import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Container, Flex } from 'theme-ui'
import { routes } from '../components/Nav'
import Logo from '../components/Logo'

interface Props {}

const Footer = (props: Props) => {
  return (
    <footer
      sx={{
        // backgroundImage: 'url(../images/footer_shape.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        bg: 'brand.veniceBlue',
        width: '100%',
        height: [650, null, null, 375],
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        borderTop: 'solid 3px white',
        px: 3,
      }}
    >
      <Container
        sx={{
          justifyContent: ['center', null, null, 'space-between'],
          alignItems: ['center', null, null, 'center'],
          borderBottom: ['none', null, null, '1px solid white'],
          pb: '40px!important',
          flexDirection: ['column', null, null, 'row'],
        }}
      >
        <div
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Logo type="white" size={233} />
          <Flex
            sx={{
              mt: 3,
              justifyContent: ['center', null, null, 'flex-start'],
            }}
          >
            <a
              href="https://www.linkedin.com/company/attain-finance"
              // sx={{
              //   mr: 2,
              // }}
            >
              <svg
                id="Layer_2"
                data-name="Layer 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 69.17 69.17"
                width={30}
              >
                <path fill="white" d="M38.08,30.59v-.08s-.04.05-.05.08h.05Z" />
                <path
                  fill="white"
                  d="M34.58,0C15.51,0,0,15.51,0,34.58s15.51,34.58,34.58,34.58,34.58-15.51,34.58-34.58S53.65,0,34.58,0ZM25.94,50.13c0,.32-.26.58-.58.58h-6.66c-.32,0-.58-.26-.58-.58v-22.37c0-.32.26-.58.58-.58h6.66c.32,0,.58.26.58.58v22.37ZM21.79,24.19c-2.31,0-4.19-1.87-4.19-4.19s1.87-4.19,4.19-4.19,4.19,1.87,4.19,4.19-1.87,4.19-4.19,4.19ZM54.14,50.13c0,.32-.26.58-.58.58h-6.67c-.32,0-.58-.26-.58-.58v-12.01c0-3.16-1.13-5.32-3.96-5.32-2.16,0-3.45,1.45-4.01,2.86-.21.5-.26,1.2-.26,1.91v12.56c0,.32-.26.58-.58.58h-6.66c-.32,0-.58-.26-.58-.58.01-3.2.08-18.72.01-22.36,0-.32.25-.58.58-.58h6.65c.32,0,.58.26.58.58v2.76c1.04-1.6,2.89-3.89,7.05-3.89,5.15,0,9,3.36,9,10.59v12.91Z"
                />
              </svg>
            </a>
            {/* <a href="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 69.17 69.17"
                width={30}
              >
                <path
                  fill="white"
                  d="M34.64,0C15.54,0,0,15.54,0,34.64s15.54,34.64,34.64,34.64,34.64-15.54,34.64-34.64S53.75,0,34.64,0ZM44.25,20.55c0,.29-.23.52-.52.52h-4.17c-1.02,0-1.85.83-1.85,1.85v4.36h5.93c.31,0,.55.26.52.57l-.52,6.15c-.02.27-.25.48-.52.48h-5.41v20.41c0,.29-.23.52-.52.52h-7.46c-.29,0-.52-.23-.52-.52v-20.41h-3.73c-.29,0-.52-.23-.52-.52v-6.15c0-.29.23-.52.52-.52h3.73v-5.93c0-4.13,3.35-7.48,7.48-7.48h7.04c.29,0,.52.23.52.52v6.15Z"
                />
              </svg>
            </a> */}
          </Flex>
        </div>
        <Flex
          sx={{
            justifyContent: 'space-between',
            width: ['100%', null, null, 400],
            alignItems: ['center', null, null, 'flex-start'],
            flexDirection: ['column', null, null, 'row'],
          }}
        >
          <Flex
            sx={{
              borderLeft: ['none', null, null, '1px solid white'],
              borderBottom: ['1px solid white', null, null, 'none'],
              flexDirection: 'column',
              justifyContent: 'center',
              pl: [0, null, null, 4],
              py: [4, null, null, 3],
              gap: 1,
            }}
          >
            {routes.map((route, i) => (
              <Link
                to={route.path}
                key={i}
                sx={{
                  color: 'white',
                  fontWeight: 'medium',
                  textAlign: ['center', null, null, 'left'],
                }}
              >
                {route.name}
              </Link>
            ))}
          </Flex>
          <Flex
            sx={{
              borderLeft: ['none', null, null, '1px solid white'],
              borderBottom: ['1px solid white', null, null, 'none'],

              flexDirection: 'column',
              justifyContent: 'center',
              pl: [0, null, null, 4],
              py: [4, null, null, 3],
              gap: 1,
              textAlign: ['center', null, null, 'left'],
            }}
          >
            <Link
              to="/privacy-policy"
              sx={{
                color: 'white',
                fontWeight: 'medium',
              }}
            >
              Privacy Policy
            </Link>
            <Link
              to="/terms-of-use"
              sx={{
                color: 'white',
                fontWeight: 'medium',
              }}
            >
              Terms of Use
            </Link>
            <a
              href="https://irdirect.net/CURO/whistleblower_iframe?template=CURO"
              sx={{
                color: 'white',
                fontWeight: 'medium',
              }}
            >
              Ethics Hotline
            </a>
          </Flex>
        </Flex>
      </Container>
      <p
        sx={{
          fontSize: 12,
          pt: 3,
          px: [4, null, null, 0],
          textAlign: 'center',
        }}
      >
        © 2025 AttainFinance. All Rights Reserved. Equal Opportunity Employer.
      </p>
    </footer>
  )
}

export default Footer
